<template>
  <v-layout column justify-center fill-height ref="layout">
    <!-- Mission question -->
    <v-flex
      d-flex
      class="mission-instructions-row"
      :class="{ 'full-size': !showOptions, invisible: mode == 'social' }"
    >
      <v-flex d-flex>
        <ResizableText :message="getCurrentMission.instructions" />
      </v-flex>
    </v-flex>
    <!-- Options -->
    <v-flex
      style="display: flex"
      justify-center
      class="mission-options"
      :class="missionOptionsClass"
    >
      <div :class="getButtonsGridClass">
        <div
          v-for="(string, index) in multipleChoice"
          :key="'multiple-choice-' + index"
          class="multiple-choice-item"
        >
          <v-btn :class="getButtonClass(index)" @click="submit(index)">
            {{ string }}
          </v-btn>
        </div>
      </div>
    </v-flex>
    <!-- Status -->
    <div class="mission-status" :class="statusTextClass">
      <div>
        {{ statusText }}
        <div class="dots" :class="{ hide: statusText !== waitingText }">
          <span>.</span><span>.</span><span>.</span>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "./ResizableText.vue"
export default {
  name: "Poll",
  components: {
    ResizableText
  },
  mixins: [GameMixin],
  props: {
    mode: String
  },
  // mounted() {},
  data() {
    return {
      waitingText: "Waiting on Scribe...",
      submited: false
    }
  },
  computed: {
    ...mapGetters([
      "missionCompleted",
      "missionPlaysArray",
      "missionAnswers",
      "getCurrentMission"
    ]),
    missionOptionsClass() {
      return {
        show: this.showOptions,
        hide: !this.showOptions
      }
    },
    user() {
      return this.$store.getters.user
    },
    hideInstructions() {
      return !this.showOptions
    },
    getButtonsGridClass() {
      const length = this.multipleChoice.length
      return length > 2 && length !== 4 ? "third" : "half"
    },
    statusTextClass() {
      const { missionCompleted, showStatus, showOptions } = this
      return {
        correct: missionCompleted,
        show: showStatus,
        hide: !showStatus,
        "hide-instant": !showOptions
      }
    },
    statusText() {
      if (this.missionCompleted) {
        return "Submitted"
      } else if (!this.isScribe && !this.missionCompleted) {
        return this.waitingText
      } else if (!this.isHost && this.isScribe && !this.missionCompleted) {
        return "Please select"
      } else {
        return ""
      }
    },
    showStatus() {
      return this.mode === "play" || this.mode === "huddle"
    },
    showOptions() {
      return this.mode === "play" || this.mode === "huddle"
    },
    multipleChoice() {
      const { getCurrentMission } = this
      const { answer1, answer2, answer3, answer4, answer5 } = getCurrentMission
      const array = []
      if (answer1) array.push(answer1)
      if (answer2) array.push(answer2)
      if (answer3) array.push(answer3)
      if (answer4) array.push(answer4)
      if (answer5) array.push(answer5)
      return array
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    isHost() {
      return this.user.role == "facilitator"
    }
  },
  watch: {
    missionCompleted(newValue, oldValue) {
      if (!newValue && oldValue) this.submited = false
    }
  },
  methods: {
    isGiven(idx) {
      return this.missionAnswers.indexOf(this.multipleChoice[idx]) > -1
    },
    isCorrectClass(idx) {
      return this.isGiven(idx)
    },
    getButtonClass(idx) {
      return {
        active: this.isScribe && !this.missionCompleted,
        result: this.missionCompleted,
        correct: this.isCorrectClass(idx)
      }
    },
    submit(n) {
      if (!this.isScribe || this.missionCompleted)
        return console.log("No rights for submit")

      console.log("Submitting the multi choice")
      this.multiAnswer = n
      this.checkAnswer()
    }
  }
}
</script>

<style scoped lang="scss">
.mission-instructions-row {
  user-select: none;
  max-height: 40%;
  padding-left: 5px;
  padding-right: 5px;
  margin: 25px 0 0;
}

.mission-instructions-row.full-size {
  max-height: 100%;
  margin-top: 21px;
  transition: all 0s ease-out 0.25s;
}

.mission-instructions-row.invisible {
  visibility: hidden;
}

.mission-instructions {
  color: $color-black;
  padding: 10px;
  line-height: 1.2em;
}

.appear-enter {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.appear-enter-active {
  opacity: 1;
}

.dots {
  display: inline-block;
  width: 0;
  margin-left: -4px;
}

.dots.hide {
  opacity: 0;
}

.dots span {
  animation-name: waiting;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes waiting {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.mission-options {
  position: relative;
  max-height: 0;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  flex-direction: column;
  margin-bottom: 42px;
}

.mission-options.show {
  max-height: 100%;
  transition: max-height 1s ease-out;
}

.mission-options.hide {
  transition: max-height 0.5s ease-out 0.25s;
}

.mission-options > div {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  max-height: 100%;
  overflow: visible;
  justify-content: center;
}

.mission-options.show > div {
  opacity: 1;
  transform: none;
  transition: all 0.5s ease-out 0.5s;
}

.mission-options.hide > div {
  opacity: 0;
  transform: translateY(10px);
  transition: transform 0.25s ease-out, opacity 0.125s ease-out;
}

.mission-status {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 42px;
  padding: 4px;
  color: $color-grey2;
  font-size: 20px;
}

.mission-status.show {
  transition: none;
}

.mission-status.hide {
  transition: max-height 0s ease-out 0.25s !important;
}

.mission-status.correct {
  color: $color-correct;
  font-weight: bold;
}

.mission-status.wrong {
  color: $color-wrong;
  font-weight: bold;
}

.mission-status > div {
  opacity: 0;
  transition: opacity 0.25s;
}

.mission-status.show > div {
  opacity: 1;
  animation-name: show-status;
  animation-duration: 0.75s;
  animation-iteration-count: forwards;
  animation-fill-mode: both;
}

@keyframes show-status {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.mission-status.hide > div {
  transform: scale(0.9);
  transition: all 0.25s;
}

.mission-status.hide-instant > div {
  opacity: 0;
  transition: none;
}

.multiple-choice-item {
  position: relative;
}

.half .multiple-choice-item {
  flex-basis: 50%;
}

.third .multiple-choice-item {
  flex-basis: 33.333333%;
}

.multiple-choice-item > button {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  padding: 6px 6px;
  color: $color-secondary-dark !important;
  border-radius: 8px;
  border: 2px solid $color-secondary-dark;
  pointer-events: none;
  box-shadow: none !important;
}

.multiple-choice-item > button.active {
  background-color: $color-secondary-dark !important;
  color: $color-white !important;
  pointer-events: auto;
  border-color: transparent;
}

.multiple-choice-item > button.active:hover {
  background: $primary_accent_color !important;
}

.multiple-choice-item {
  background-color: $color-white !important;
  color: $color-secondary-dark !important;
  pointer-events: none;
  border-color: $color-secondary-dark;
}

.multiple-choice-item > button.correct {
  background-color: $color-correct !important;
  color: $color-white !important;
  pointer-events: none;
  border-color: $color-correct;
}

.multiple-choice-item > button.wrong {
  background-color: $color-wrong !important;
  color: $color-white !important;
  pointer-events: none;
  border-color: $color-wrong;
}

.multiple-choice-item {
  padding: 6px;
}

@media (max-width: 1400px) {
  .mission-status {
    font-size: 19px;
  }
}

@media (max-width: 1200px) {
  .mission-instructions-row {
    max-height: 42%;
  }
  .multiple-choice-item {
    padding: 4px;
  }
  .multiple-choice-item > button {
    font-size: 13px;
    padding: 5px 6px;
  }
  .mission-status {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .mission-instructions-row {
    max-height: 46%;
  }
  .multiple-choice-item {
    padding: 3px;
  }
  .multiple-choice-item > button {
    padding: 4px 6px;
  }
  .mission-status {
    font-size: 17px;
  }
}

@media (max-width: 900px) {
  .mission-instructions-row {
    max-height: 50%;
  }
  .multiple-choice-item {
    padding: 2px;
  }
  .multiple-choice-item > button {
    font-size: 12px;
  }
  .multiple-choice-item > button {
    padding: 3px 5px;
  }
  .mission-status {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .third .multiple-choice-item {
    flex-basis: 50%;
  }
  .multiple-choice-item > button {
    padding: 2px 4px;
  }
  .mission-status {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .mission-status {
    padding-left: 56px;
    text-align: left;
  }
}

@media (max-width: 538px) {
  .third .multiple-choice-item {
    flex-basis: 100%;
  }
  .multiple-choice-item {
    padding: 1px;
  }
  .multiple-choice-item > button {
    padding: 0 4px;
  }
  .mission-status {
    font-size: 12px;
  }
}
</style>
