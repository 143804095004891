var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      ref: "layout",
      attrs: { column: "", "justify-center": "", "fill-height": "" }
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "mission-instructions-row",
          class: {
            "full-size": !_vm.showOptions,
            invisible: _vm.mode == "social"
          },
          attrs: { "d-flex": "" }
        },
        [
          _c(
            "v-flex",
            { attrs: { "d-flex": "" } },
            [
              _c("ResizableText", {
                attrs: { message: _vm.getCurrentMission.instructions }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticClass: "mission-options",
          class: _vm.missionOptionsClass,
          staticStyle: { display: "flex" },
          attrs: { "justify-center": "" }
        },
        [
          _c(
            "div",
            { class: _vm.getButtonsGridClass },
            _vm._l(_vm.multipleChoice, function(string, index) {
              return _c(
                "div",
                {
                  key: "multiple-choice-" + index,
                  staticClass: "multiple-choice-item"
                },
                [
                  _c(
                    "v-btn",
                    {
                      class: _vm.getButtonClass(index),
                      on: {
                        click: function($event) {
                          return _vm.submit(index)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(string) + " ")]
                  )
                ],
                1
              )
            }),
            0
          )
        ]
      ),
      _c("div", { staticClass: "mission-status", class: _vm.statusTextClass }, [
        _c("div", [
          _vm._v(" " + _vm._s(_vm.statusText) + " "),
          _c(
            "div",
            {
              staticClass: "dots",
              class: { hide: _vm.statusText !== _vm.waitingText }
            },
            [
              _c("span", [_vm._v(".")]),
              _c("span", [_vm._v(".")]),
              _c("span", [_vm._v(".")])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }